import useOrders from "hooks/useOrders"
import { useEffect, useState } from "react"
import Header from "./Header"
import moment from "moment"
import useProvider from "hooks/useProvider"
import useMultilang from "hooks/useMultilang"
import LoadingComponent from "components/LoadingComponent"
import FormattedNumber from "components/FormattedNumber"

function Stats() {
  const { getReportSums } = useOrders()
  const { selectedProvider } = useProvider()

  const { getT } = useMultilang()
  const [filter, setFilter] = useState({
    dateFrom: moment().format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
    timeFrom: "00:00",
    timeTo: "23:59",
  })

  const [data, setData] = useState({
    sum: 0,
    quantity: 0,
    tippSum: 0,
    vatSumA: 0,
    vatSumB: 0,
    vatSumC: 0,
    vatSumD: 0,
    vatSumE: 0,
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedProvider?.id != null) {
      let formattedFilter = {
        dateFrom: `${filter?.dateFrom} ${filter?.timeFrom}`,
        dateTo: `${filter?.dateTo} ${filter?.timeTo}`,
      }
      setLoading(true)
      getReportSums(formattedFilter)
        .then((res) => {
          if (res.success) {
            setData(res.data)
          } else {
            setData({
              sum: 0,
              quantity: 0,
              tippSum: 0,
              vatSumA: 0,
              vatSumB: 0,
              vatSumC: 0,
              vatSumD: 0,
              vatSumE: 0,
            })
          }
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line
  }, [filter, selectedProvider])

  return (
    <div className="stats-root">
      <Header onChange={(values) => setFilter(values)} />
      <div className="stats-content">
        <LoadingComponent isLoading={loading} />
        <div className="label">{getT("ntak.stats.sum")}</div>
        <div className="value">
          <FormattedNumber value={data?.sum || 0} />
        </div>
        <div className="label">{getT("ntak.stats.quantity")}</div>
        <div className="value">{data?.quantity || 0}</div>
        <div className="label">{getT("ntak.stats.tippSum")}</div>
        <div className="value">
          <FormattedNumber value={data?.tippSum || 0} />
        </div>
        <div className="label">{getT("ntak.stats.vatSumA")}</div>
        <div className="value">
          <FormattedNumber value={data?.vatSumA || 0} />
        </div>
        <div className="label">{getT("ntak.stats.vatSumB")}</div>
        <div className="value">
          <FormattedNumber value={data?.vatSumB || 0} />
        </div>
        <div className="label">{getT("ntak.stats.vatSumC")}</div>
        <div className="value">
          <FormattedNumber value={data?.vatSumC || 0} />
        </div>
        <div className="label">{getT("ntak.stats.vatSumD")}</div>
        <div className="value">
          <FormattedNumber value={data?.vatSumD || 0} />
        </div>
        <div className="label">{getT("ntak.stats.vatSumE")}</div>
        <div className="value">
          <FormattedNumber value={data?.vatSumE || 0} />
        </div>
      </div>
    </div>
  )
}
export default Stats
