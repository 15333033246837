import Select from "react-select"
import useUser from "hooks/useUser"
import useMultilang from "hooks/useMultilang"

function InputSelect(props) {
  const { getT } = useMultilang()
  const { settings } = useUser()

  //"#eceff1" : "#27282C",

  const handleTheme = (theme) => {
    const color1 = "#0c85d5"
    const color2 = settings?.lightMode ? "#eceff1" : "#27282C"
    const color3 = settings?.lightMode ? "#27282C" : "#eceff1"

    const colors = {
      primary: color1,
      primary75: color1,
      primary50: color1,
      primary25: color2,
      danger: "#DE350B",
      dangerLight: "#FFBDAD",
      neutral0: color2,
      neutral5: color2,
      neutral10: color2,
      neutral20: settings?.lightMode
        ? "rgba(0, 0, 0, 0.2)"
        : "rgba(255, 255, 255, 0.1)",
      neutral30: color1,
      neutral40: color1,
      neutral50: color1,
      neutral60: color1,
      neutral70: color1,
      neutral80: color3,
      neutral90: color1,
    }

    return {
      ...theme,
      colors,
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: settings?.lightMode
        ? state.isSelected || state.isFocused
          ? "#eceff1"
          : "#27282C"
        : "#eceff1",
    }),
  }

  const customNoOptionsMessage = () => {
    return getT("ntak.select.noOptions")
  }

  return (
    <div className="input-select">
      <Select
        theme={handleTheme}
        styles={{ ...customStyles }}
        placeholder={getT("ntak.select.select")}
        noOptionsMessage={customNoOptionsMessage}
        {...props}
      />
    </div>
  )
}
export default InputSelect
