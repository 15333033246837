import useUser from "hooks/useUser"
import Switch from "components/Switch"
import useSvg from "hooks/useSvg"

function LightModeSwitch() {
  const { settings, setLightMode } = useUser()
  const { getSvg } = useSvg()

  return (
    <div
      className={`light-mode-switch-root ${
        settings?.sidebarClosed ? "side-bar-closed" : "side-bar-open"
      }`}
    >
      {settings?.sidebarClosed && (
        <div
          className="switch-icon"
          onClick={() => setLightMode(!settings?.lightMode)}
        >
          {getSvg("lightDark")}
        </div>
      )}
      {!settings?.sidebarClosed && (
        <div className="light-mode-switch-container">
          <div
            className="light-mode-switch-icon"
            onClick={() => setLightMode(false)}
          >
            {getSvg(settings?.lightMode ? "moonL" : "moon")}
          </div>
          <Switch
            name="lightMode"
            value={settings?.lightMode}
            onChange={(e) => setLightMode(e.target.value)}
          />
          <div
            className="light-mode-switch-icon"
            onClick={() => setLightMode(true)}
          >
            {getSvg(settings?.lightMode ? "sunL" : "sun")}
          </div>
        </div>
      )}
    </div>
  )
}
export default LightModeSwitch
