import { lazy, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen"
import { ErrorBoundary } from "react-error-boundary"
import ErrorHandler from "utils/ErrorHandler"
import useUser from "hooks/useUser"
import Login from "views/Login"
import Stats from "views/Stats"

const Providers = lazy(() => import("views/Providers"))
const Orders = lazy(() => import("views/Orders"))
const FAQ = lazy(() => import("views/FAQ"))
const Days = lazy(() => import("views/Days"))
const Users = lazy(() => import("views/Users"))
const Tokens = lazy(() => import("views/Tokens"))

function Router() {
  const { loading } = useUser()

  if (loading) return <div className="loading-screen">Loading...</div>

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route
          path="/"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Providers />
            </ErrorBoundary>
          }
        />

        <Route
          path="/providers"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Providers />
            </ErrorBoundary>
          }
        />

        <Route
          path="/providers/:regnumber"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Providers />
            </ErrorBoundary>
          }
        />

        <Route
          path="/orders"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Orders />
            </ErrorBoundary>
          }
        />

        <Route
          path="/faq"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <FAQ />
            </ErrorBoundary>
          }
        />

        <Route
          path="/days"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Days />
            </ErrorBoundary>
          }
        />

        <Route
          path="/users"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Users />
            </ErrorBoundary>
          }
        />
        <Route
          path="/tokens"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Tokens />
            </ErrorBoundary>
          }
        />
        <Route
          path="/adminlogin"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Login admin={true} />
            </ErrorBoundary>
          }
        />
        <Route
          path="/stats"
          element={
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Stats />
            </ErrorBoundary>
          }
        />
      </Routes>
    </Suspense>
  )
}
export default Router
