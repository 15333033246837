import { useState, createContext, useEffect } from "react"
import useUser from "hooks/useUser"
import useProvider from "hooks/useProvider"
import { toast } from "react-toastify"
import useMultilang from "hooks/useMultilang"
import { ntakTokens } from "apis/token/token"

const NtakTokensContext = createContext()

const NtakTokensProvider = (props) => {
  const { userToken } = useUser()
  const { selectedProvider } = useProvider()
  const { getT } = useMultilang()
  // eslint-disable-next-line
  const [tokens, setTokens] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedProvider?.id != null) {
      reloadTokens()
    }
    // eslint-disable-next-line
  }, [userToken, selectedProvider])

  const reloadTokens = (withoutLoading) => {
    if (!withoutLoading) setLoading(true)
    if (withoutLoading) {
      toast.info(getT("ntakTokens.reloaded"))
    }
    ntakTokens
      .getTokens(userToken, selectedProvider?.id)
      .then((res) => {
        if (res.success) {
          setTokens(res.data)
        } else {
          console.log(res)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
  }

  const generateToken = (roles, expiry, label) => {
    return ntakTokens.generateToken(
      userToken,
      selectedProvider?.id,
      roles,
      expiry,
      label
    )
  }

  const disableToken = (tokenID) => {
    return ntakTokens.disableToken(userToken, selectedProvider?.id, tokenID)
  }

  const getTokenData = (tokenID) => {
    return ntakTokens.getToken(userToken, selectedProvider?.id, tokenID)
  }

  return (
    <NtakTokensContext.Provider
      value={{
        loading,
        reloadTokens,
        tokens,
        getTokenData,
        generateToken,
        disableToken,
      }}
    >
      {props.children}
    </NtakTokensContext.Provider>
  )
}

export { NtakTokensContext, NtakTokensProvider }
