import { useRef } from "react"
import { useState } from "react"
import { useEffect } from "react"
import { PuffLoader } from "react-spinners"

function LoadingComponent({ isLoading }) {
  const timer = useRef(null)
  const [notVisible, setNotVisible] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setNotVisible(false)
      timer.current = setTimeout(() => {
        setNotVisible(true)
      }, 16000)
    } else {
      clearTimeout(timer.current)
    }
  }, [isLoading])

  return (
    <div
      className={`loading-component ${
        notVisible ? "not-visible" : isLoading ? "loading" : "not-visible"
      }`}
    >
      <PuffLoader color="#2e9bd9" />
    </div>
  )
}
export default LoadingComponent
