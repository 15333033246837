import { useEffect, useRef, useState } from "react"
import InputElement from "./InputElement"

function PinInput(props) {
  const {
    length,
    focusOnLoad,
    onComplete,
    onChange,
    disabled,
    type = "numeric",
    inputMode,
    autoSelect,
    regexCriteria,
    ariaLabel,
    placeholder,
    className,
  } = props

  const [values, setValues] = useState(null)
  const elements = useRef(Array(length).fill(null))

  useEffect(() => {
    let initValues = Array(length).fill("")
    setValues(initValues)
  }, [length])

  useEffect(() => {
    if (focusOnLoad && length && values != null) elements.current[0].focus()
  }, [focusOnLoad, length, values])

  const onItemChange = (value, isPasting, index) => {
    let currentIndex = index
    values[index] = value

    // Set focus on next
    if (value.length === 1 && index < length - 1) {
      currentIndex += 1
      elements.current[currentIndex].focus()
    }

    // Notify the parent
    const pin = values.join("")

    if (!isPasting && typeof onChange === "function") {
      onChange(pin, currentIndex)
    }

    if (pin.length === length) {
      // for pasting, trigger onComplete only when the last input triggers onChange
      if (isPasting && index < length - 1) {
        return
      }
      if (typeof onComplete === "function") onComplete(pin, currentIndex)
    }
  }

  const onBackspace = (index) => {
    if (index > 0) {
      elements.current[index - 1].focus()
    }
  }

  const onPaste = (value) => {
    if (value.length !== length) {
      return
    }

    elements.current.forEach((el, index) => {
      el.value = value[index]
      onItemChange(value[index], true, index)
    })
  }

  if (values == null) return null
  return (
    <div
      className={`pincode-input-container ${
        className != null ? className : ""
      }`}
    >
      {values.map((e, i) => {
        return (
          <InputElement
            initialValue={e}
            ref={(el) => (elements.current[i] = el)}
            index={i}
            key={i}
            disabled={disabled}
            onBackspace={() => onBackspace(i)}
            onChange={(v, isPasting) => onItemChange(v, isPasting, i)}
            type={type}
            inputMode={inputMode}
            autoSelect={autoSelect}
            onPaste={i === 0 ? onPaste : null}
            regexCriteria={regexCriteria}
            ariaLabel={ariaLabel}
            placeholder={placeholder}
          />
        )
      })}
    </div>
  )
}
export default PinInput
