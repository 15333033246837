import useMultilang from "hooks/useMultilang"
import useSvg from "hooks/useSvg"

function Button(props) {
  const { className, onClick, value, type, iconName, disabled } = props

  const { getT } = useMultilang()
  const { getSvg } = useSvg()

  const handleClick = () => {
    if (typeof onClick === "function" && !disabled) onClick()
  }

  const text = {
    save: getT("ntak.btn.save"),
    cancel: getT("ntak.btn.cancel"),
    add: getT("ntak.btn.add"),
  }

  const icon = {
    save: getSvg("save"),
    cancel: getSvg("cross"),
    add: getSvg("plus"),
  }

  return (
    <div
      className={`btn ${className != null ? className : ""} ${
        type != null ? type : ""
      } ${icon?.[type] || iconName ? "icon-btn" : ""} ${
        disabled ? "disabled" : ""
      }`}
      onClick={handleClick}
    >
      {icon?.[type] || (iconName ? getSvg(iconName) : "")}
      {text?.[type] || value}
    </div>
  )
}
export default Button
