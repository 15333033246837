import { useContext } from "react"
import { ProviderContext } from "contexts/ProviderContext"

export default function useProvider() {
  const {
    selectedProvider,
    providers,
    selectProvider,
    setFilter,
    filter,
    addProvider,
    reloadProviders,
    generateProviderKey,
    editProvider,
    recheck,
    copyCert,
    getProvider,
    selectProviderByRegnumber,
  } = useContext(ProviderContext)

  return {
    selectedProvider,
    providers,
    selectProvider,
    setFilter,
    filter,
    addProvider,
    reloadProviders,
    generateProviderKey,
    editProvider,
    recheck,
    copyCert,
    getProvider,
    selectProviderByRegnumber,
  }
}
