import axios from "axios"
import config from "config"

export const userProfile = {
  getProfile: async (token) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/profile`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  setProfile: async (token, userData) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/profile`, { ...userData })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
}
