import { useState, createContext, useEffect } from "react"
import useUser from "hooks/useUser"
import useProvider from "hooks/useProvider"
import { ntakOrders } from "apis/ntak/order"
import { toast } from "react-toastify"
import useMultilang from "hooks/useMultilang"

const OrdersContext = createContext()

const OrdersProvider = (props) => {
  const { userToken } = useUser()
  const { selectedProvider } = useProvider()
  const { getT } = useMultilang()
  // eslint-disable-next-line
  const [orders, setOrders] = useState([])
  const [filter, setFilter] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedProvider?.id != null) {
      reloadOrders()
    }
    // eslint-disable-next-line
  }, [userToken, filter, selectedProvider])

  const reloadOrders = (withoutLoading) => {
    if (!withoutLoading) setLoading(true)
    if (withoutLoading) {
      toast.info(getT("orders.reloaded"))
    }
    ntakOrders
      .getOrders(userToken, selectedProvider?.id, filter)
      .then((res) => {
        if (res.success) {
          setOrders(res.data)
        } else {
          console.log(res)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
  }

  const getOrderVersions = (orderUID) => {
    return ntakOrders.getOrder(userToken, selectedProvider?.id, orderUID)
  }

  const saveOrder = (order) => {
    return ntakOrders.setOrder(userToken, selectedProvider?.id, order)
  }

  const getReportSums = (filter) => {
    return ntakOrders.getReportSums(userToken, selectedProvider?.id, filter)
  }

  return (
    <OrdersContext.Provider
      value={{
        orders,
        setFilter,
        filter,
        reloadOrders,
        loading,
        getOrderVersions,
        saveOrder,
        getReportSums,
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  )
}

export { OrdersContext, OrdersProvider }
