import { useNavigate } from "react-router-dom"

function ErrorFallback({ error, resetErrorBoundary }) {
  const navigate = useNavigate()

  console.log(error)

  const resetApp = () => {
    navigate("/")
    window.location.reload()
  }

  return (
    <div className="error-message-window">
      <div className="error-message-title">Hiba történt</div>
      <div className="error-message-message">Valami itt nem stimmel...</div>
      <div className="error-message-actions">
        <div
          className="error-message-action try-again"
          onClick={resetErrorBoundary}
        >
          Újra próbálom
        </div>
        <div
          className="error-message-action go-back"
          onClick={() => {
            navigate(-1)
          }}
        >
          Vissza
        </div>
        <div className="error-message-action reset-app" onClick={resetApp}>
          App újraindítása
        </div>
      </div>
    </div>
  )
}

export default ErrorFallback
