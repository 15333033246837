import useSvg from "hooks/useSvg"
import useMultilang from "hooks/useMultilang"
import useUser from "hooks/useUser"

function UserLanguages() {
  const { setLanguage, language } = useMultilang()
  const { settings } = useUser()

  const { getSvg } = useSvg()
  return (
    <div className={`user-languages ${settings?.sidebarClosed ? "small" : ""}`}>
      <div
        className={`language ${language === "hu" ? "selected" : ""}`}
        onClick={() => setLanguage("hu")}
      >
        {getSvg("hu")}
      </div>
      <div
        className={`language ${language === "en" ? "selected" : ""}`}
        onClick={() => setLanguage("en")}
      >
        {getSvg("en")}
      </div>
    </div>
  )
}
export default UserLanguages
