import { useEffect, useState } from "react"
import InputSelect from "components/InputSelect"
import useProvider from "hooks/useProvider"
import useUser from "hooks/useUser"
import useMultilang from "hooks/useMultilang"
import LoadingComponent from "components/LoadingComponent"

function ProviderSelector() {
  const { selectedProvider, providers, selectProvider } = useProvider()
  const { isAdmin } = useUser()
  const { getT } = useMultilang()

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let optionsArray = Object.values(providers || {}).map((provider) => ({
      value: provider.id,
      label: provider.label,
    }))

    if (isAdmin)
      optionsArray.unshift({
        value: 0,
        label: getT("ntak.providers.allProvider"),
      })

    setOptions(optionsArray)
    // eslint-disable-next-line
  }, [isAdmin, providers])

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      setLoading(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [options])

  const handleChange = (option) => {
    selectProvider(option.value)
  }

  if (loading)
    return (
      <div className="provider-selector-root hidden appear">
        <LoadingComponent isLoading={true} />
      </div>
    )

  return (
    <div className="provider-selector-root hidden appear">
      <InputSelect
        options={options}
        defaultValue={
          selectedProvider?.id != null
            ? {
                value: selectedProvider?.id,
                label: selectedProvider?.label,
              }
            : null
        }
        onChange={handleChange}
      />
    </div>
  )
}
export default ProviderSelector
