import axios from "axios"
import config from "config"

export const ntakProviders = {
  getProviders: async (token, filter = {}) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const axiosconfig = {
        params: {
          filter,
        },
      }
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/ntak/provider/`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  getProvider: async (token, providerID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/ntak/provider/${providerID}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  addProvider: async (token, regNumber, label, taxNumber) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/ntak/provider`, {
          regNumber,
          label,
          taxNumber,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  editProvider: async (token, providerID, data) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/ntak/provider`, {
          ...data,
          id: providerID,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  copyCert: async (token, providerID, toProviderID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/ntak/provider/copyCert`, {
          toProviderID,
          providerID,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  generateProviderKey: async (token, providerID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/ntak/provider/generate`, {
          providerID,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
}
