import { useState, createContext, useEffect } from "react"
import useUser from "hooks/useUser"
import { ntakProviders } from "apis/ntak/provider"
import { ntakControl } from "apis/control/rms"
import useMultilang from "hooks/useMultilang"
import LoadingComponent from "components/LoadingComponent"

const ProviderContext = createContext()

const ProviderProvider = (props) => {
  const { saveS, settings, isAdmin, userToken } = useUser()
  const { getT } = useMultilang()
  // eslint-disable-next-line
  const [providers, setProviders] = useState({})
  const [filter, setFilter] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState(
    settings?.selectedProviderID
      ? providers?.[settings?.selectedProviderID]
      : null
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    reloadProviders()
    // eslint-disable-next-line
  }, [userToken, filter])

  useEffect(() => {
    if (
      settings?.selectedProviderID != null &&
      settings?.selectedProviderID !== selectedProvider?.id
    ) {
      let provider =
        settings?.selectedProviderID === 0 && isAdmin
          ? { id: 0, label: getT("ntak.providers.allProvider") }
          : providers?.[settings?.selectedProviderID]
      setSelectedProvider(provider)
    }
    // eslint-disable-next-line
  }, [settings, providers, isAdmin])

  const reloadProviders = () => {
    if (userToken != null) {
      setLoading(true)
      ntakProviders
        .getProviders(userToken, JSON.stringify(filter || {}))
        .then((res) => {
          if (res.success) {
            let obj = {}
            for (let row of res.data) {
              obj[row.id] = row
            }
            setProviders(obj)
          } else {
            console.log(res)
          }
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false))
    }
  }

  const addProvider = (regNumber, label, taxNumber) => {
    return ntakProviders.addProvider(userToken, regNumber, label, taxNumber)
  }

  const editProvider = (providerID, data) => {
    return ntakProviders.editProvider(userToken, providerID, data)
  }

  const generateProviderKey = (providerID) => {
    return ntakProviders.generateProviderKey(userToken, providerID)
  }

  const recheck = (id) => {
    return ntakControl.reload(userToken, selectedProvider?.id, id)
  }

  const selectProvider = (providerID) => {
    if (providerID !== selectedProvider?.id) {
      saveS("selectedProviderID", providerID)
    }
  }

  const getProvider = (providerID) => {
    return ntakProviders.getProvider(userToken, providerID)
  }

  const copyCert = (providerID, toProviderID) => {
    return ntakProviders.copyCert(userToken, providerID, toProviderID)
  }

  const selectProviderByRegnumber = (regnumber) => {
    if (regnumber == null) return null

    for (let provider of Object.values(providers)) {
      if (provider.regNumber === regnumber) selectProvider(provider.id)
    }
  }

  return (
    <ProviderContext.Provider
      value={{
        selectedProvider,
        selectProvider,
        providers,
        setFilter,
        filter,
        addProvider,
        reloadProviders,
        generateProviderKey,
        editProvider,
        recheck,
        loading,
        copyCert,
        getProvider,
        selectProviderByRegnumber,
      }}
    >
      <LoadingComponent isLoading={loading} />
      {props.children}
    </ProviderContext.Provider>
  )
}

export { ProviderContext, ProviderProvider }
