import axios from "axios"
import config from "config"

export const ntakUsers = {
  getUsers: async (token, providerID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/user?providerID=${providerID}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  getUser: async (token, providerID, userID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(
          `${config().apiBaseUrl}/api/user/${userID}?providerID=${providerID}`
        )
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  setUser: async (token, providerID, user) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/user`, { providerID, user })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  setUserToProvider: async (token, providerID, userID, roles, enabled) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/user/provider`, {
          providerID,
          data: { userID, roles, enabled },
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  checkUserEmail: async (token, providerID, email) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(
          `${
            config().apiBaseUrl
          }/api/user/email/${email}?providerID=${providerID}`
        )
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
}
