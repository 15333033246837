import axios from "axios"
import config from "config"

export const userLogin = {
  generateUserPin: async (email) => {
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .post(`${config().apiBaseUrl}/api/auth/pin`, { email })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  generateUserToken: async (email, pinCode) => {
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .post(`${config().apiBaseUrl}/api/auth/login`, { email, pinCode })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  generateSSOUserToken: async (uuid) => {
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .post(`${config().apiBaseUrl}/api/auth/loginSso`, { uuid })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  refreshUserToken: async (token) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/auth/refresh`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
}
