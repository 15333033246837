import axios from "axios"
import config from "config"

export const ntakOrders = {
  getOrders: async (token, providerID, filter = {}) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const axiosconfig = {
        params: {
          providerID,
          filter: JSON.stringify(filter),
        },
      }
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/ntak/order`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  getOrder: async (token, providerID, orderUID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const axiosconfig = {
        params: {
          providerID,
          orderUID,
        },
      }
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/ntak/order/versions`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  getReportSums: async (token, providerID, filter) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const axiosconfig = {
        params: {
          providerID,
          filter: JSON.stringify(filter || {}),
        },
      }
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/ntak/order/report/sums`, axiosconfig)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  setOrder: async (token, providerID, order) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/ntak/order`, {
          providerID,
          order,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
}
