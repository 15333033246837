import { useContext } from "react"
import { OrdersContext } from "contexts/OrdersContext"

export default function useOrders() {
  const {
    orders,
    setFilter,
    filter,
    reloadOrders,
    loading,
    getOrderVersions,
    saveOrder,
    getReportSums,
  } = useContext(OrdersContext)

  return {
    orders,
    setFilter,
    filter,
    reloadOrders,
    loading,
    getOrderVersions,
    saveOrder,
    getReportSums,
  }
}
