import axios from "axios"
import config from "config"

export const ntakTokens = {
  getTokens: async (token, providerID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(`${config().apiBaseUrl}/api/token?providerID=${providerID}`)
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  getToken: async (token, providerID, tokenID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .get(
          `${config().apiBaseUrl}/api/token/${tokenID}?providerID=${providerID}`
        )
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  generateToken: async (token, providerID, roles, expiry, label) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/token/generate`, {
          providerID,
          roles,
          expiry,
          label,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
  disableToken: async (token, providerID, tokenID) => {
    if (token == null) return { success: false, data: null, errorCode: 450 }
    try {
      const response = await axios
        .create({
          timeout: 15000,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post(`${config().apiBaseUrl}/api/token/disable`, {
          providerID,
          tokenID,
        })
      return response.data
    } catch (error) {
      console.log(error)
      return { success: false, data: null }
    }
  },
}
