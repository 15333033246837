import { NumericFormat } from "react-number-format"

function FormattedNumber(props) {
  return (
    <NumericFormat
      decimalSeparator=" "
      suffix=" Ft"
      thousandSeparator="."
      displayType="text"
      {...props}
    />
  )
}
export default FormattedNumber
