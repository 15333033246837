import { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { userLogin } from "apis/auth/login"
import useUser from "hooks/useUser"

function SSOLogin() {
  const navigate = useNavigate()
  const location = useLocation()

  const { refreshToken } = useUser()

  useEffect(() => {
    const uuid = location.pathname.split("/")[2]

    if (uuid != null) {
      userLogin.generateSSOUserToken(uuid).then((res) => {
        console.log(res)
        if (res.success) {
          navigate("/")
          refreshToken(res.data.token)
        } else {
          window.location.href = "https://ipanel.barsoft.hu"
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div className="sso-container">Azonosítás folyamatban, kérlek várj!</div>
  )
}
export default SSOLogin
