import useUser from "hooks/useUser"
import useMultilang from "hooks/useMultilang"

function UserProfile() {
  const { user } = useUser()
  const { getT, language } = useMultilang()

  return (
    <div className="side-bar-profile">
      {language === "hu" && (
        <div className="user-name">{`${user?.lastName} ${user?.firstName}`}</div>
      )}
      {language !== "hu" && (
        <div className="user-name">{`${user?.firstName} ${user?.lastName}`}</div>
      )}
      <div className="user-email">{user?.email}</div>
      <div className="user-roles">
        {(user?.roles || []).map((role) => (
          <div key={role} className="user-role">
            {getT(`ntak.roles.${role}`)}
          </div>
        ))}
      </div>
    </div>
  )
}
export default UserProfile
