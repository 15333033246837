import { useContext } from "react"
import { UserContext } from "contexts/UserContext"

export default function useUser() {
  const {
    user,
    settings,
    saveSettings,
    setStyles,
    loading,
    generatePin,
    generateToken,
    loginError,
    loginMessage,
    emailSubmitted,
    resetLogin,
    loggedIn,
    logout,
    newVersionAvailable,
    userToken,
    isAdmin,
    translations,
    selectedLanguage,
    setSelectedLanguage,
    refreshToken,
  } = useContext(UserContext)

  const setLightMode = (lightMode) => {
    saveSettings("lightMode", lightMode)
    setStyles(lightMode)
  }

  const toggleSidebar = () => {
    saveSettings("sidebarClosed", !settings?.sidebarClosed)
  }

  return {
    user,
    settings,
    saveS: saveSettings,
    setLightMode,
    toggleSidebar,
    loading,
    generatePin,
    generateToken,
    loginError,
    loginMessage,
    emailSubmitted,
    resetLogin,
    loggedIn,
    logout,
    newVersionAvailable,
    userToken,
    isAdmin,
    translations,
    selectedLanguage,
    setSelectedLanguage,
    refreshToken,
  }
}
