function Switch(props) {
  const { name, value, onChange } = props

  const handleChange = () => {
    if (typeof onChange === "function") {
      onChange({ target: { name, value: !value } })
    }
  }

  return (
    <div
      className={`switch-root ${value ? "checked" : ""}`}
      onClick={handleChange}
    >
      <div className={`switch-inner ${value ? "checked" : ""}`}></div>
    </div>
  )
}
export default Switch
