import { useNavigate, useLocation } from "react-router-dom"
import useSvg from "hooks/useSvg"
import useMultilang from "hooks/useMultilang"

function MenuElement(props) {
  const { viewName, url } = props
  const navigate = useNavigate()
  const location = useLocation()
  const { getSvg } = useSvg()
  const { getT } = useMultilang()

  const naviToUrl = () => {
    window.open(url)
  }

  return (
    <div
      className={`menu-element ${
        location.pathname.includes(viewName) ? "selected" : ""
      }`}
      onClick={() => (url == null ? navigate(`/${viewName}`) : naviToUrl())}
    >
      {getSvg(viewName)}
      <div className="menu-text">{getT(`ntak.navigation.${viewName}`)}</div>
    </div>
  )
}
export default MenuElement
