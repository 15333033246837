import useUser from "hooks/useUser";
import { dictionaryGlobal } from "apis/dictionary/global";

export default function useMultilang() {
  const { selectedLanguage, translations, setSelectedLanguage } = useUser();

  const getT = (key) => {
    let token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMTNFOUE5NjMyN0JDRUNBMDkxQThGQjRBRDMzNTkxRkYiLCJpYXQiOjE3MDI5OTU4NzAsImV4cCI6MTcwNDgxMDI3MH0.OOev6WYyxk_oVcbZo2RbJoxzrBqHEuKhGWfiLA3IuAI";
    if (
      key != null &&
      process.env.NODE_ENV === "development" &&
      translations?.[key] == null
    ) {
      dictionaryGlobal.addTranslation(token, key).then((res) => {
        if (res.success) {
          console.log(res);
        } else {
          console.log(res);
        }
      });
    }
    return translations?.[key] && translations?.[key] !== ""
      ? translations?.[key]
      : key;
  };

  return {
    language: selectedLanguage,
    translations,
    getT,
    setLanguage: setSelectedLanguage,
  };
}
