export default function config() {
  return {
    apiBaseUrl:
      window.location.hostname === "localhost"
        ? "https://ntak.fruitsys.hu"
        : `https://${window.location.hostname}`,
  };
}
//"https://ntak.app.barsoft.hu"
//"https://ntak.fruitsys.hu"
