import { useEffect, useState } from "react"
import PinInput from "components/PinInput"
import useMultilang from "hooks/useMultilang"
import useUser from "hooks/useUser"
import LoadingScreen from "components/LoadingScreen"
import Button from "components/Button"

function Login({ admin }) {
  const {
    generateToken,
    generatePin,
    loginError,
    loginMessage,
    emailSubmitted,
    resetLogin,
    loading,
  } = useUser()
  const { getT } = useMultilang()
  const [pinCode, setPinCode] = useState("")
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [email, setEmail] = useState(localStorage["NTAK-userEmail"])

  useEffect(() => {
    function enterFunction(e) {
      if (e.key === "Enter") {
        handleSubmitEmail()
      }
    }

    document.addEventListener("keydown", enterFunction, false)

    return () => {
      document.removeEventListener("keydown", enterFunction, false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (pinCode.length === 4 && email != null) {
      generateToken(email, pinCode)
    }
    // eslint-disable-next-line
  }, [pinCode])

  const handleChange = (e) => {
    setEmailInvalid(false)
    setEmail(e.target.value)
    localStorage["NTAK-userEmail"] = e.target.value
  }

  const handlePinChange = (code) => {
    setPinCode(code)
  }

  const handleSubmitEmail = () => {
    let email = localStorage["NTAK-userEmail"]
    let emailValid = validateEmail(email)
    if (email == null || email === "" || !emailValid) {
      setEmailInvalid(true)
    } else {
      setEmailInvalid(false)
      generatePin(email)
    }
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  if (loading) return <LoadingScreen />

  if (!admin && window.location.host.includes("barsoft.hu"))
    return (
      <div className="login-container-root">
        <div className="hidden appear login-box">
          <div className="logged-out">{getT("ntak.login.goBackIpanel")}</div>
        </div>
      </div>
    )

  return (
    <div className="login-container-root">
      <div className="hidden appear login-box">
        <div className="">Kérjük adja meg saját email címét!</div>
        <div className="input-label">{getT("ntak.login")}</div>
        <div className="input-container">
          <input
            onChange={handleChange}
            value={email || ""}
            placeholder="you@yourdomain.com"
            disabled={emailSubmitted}
          />
          {emailInvalid && (
            <div className="email-msg">{getT("ntak.login.invalidEmail")}</div>
          )}
          {loginMessage !== "" && (
            <div className="email-msg">{getT(loginMessage)}</div>
          )}
          {loginError !== "" && (
            <div className="email-msg">{getT(loginError)}</div>
          )}
        </div>
        {!emailSubmitted && (
          <div className="btn-container">
            <Button
              className="btn-primary"
              onClick={() => handleSubmitEmail()}
              value={getT("ntak.login.submit")}
            />
          </div>
        )}
        {emailSubmitted && (
          <div className="hidden appear">
            <div className="pin-label">{getT("ntak.login.pinLabel")}</div>
            <PinInput
              length={4}
              onComplete={handlePinChange}
              className="custom-style"
              focusOnLoad={true}
            />
            <div className="reset" onClick={() => resetLogin()}>
              {getT("ntak.login.reset")}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Login
