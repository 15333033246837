import { useRef, useState } from "react"
import ProviderSelector from "components/ProviderSelector"
import moment from "moment"
import Button from "components/Button"
import useMultilang from "hooks/useMultilang"
import useSvg from "hooks/useSvg"
import useProvider from "hooks/useProvider"

function Header({ onChange }) {
  const [filter, setFilter] = useState({
    dateFrom: moment().format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
    timeFrom: "00:00",
    timeTo: "23:59",
  })
  const {
    filter: providerFilter,
    setFilter: setProviderFilter,
    reloadProviders,
  } = useProvider()
  const { getT } = useMultilang()
  const { getSvg } = useSvg()
  const timer = useRef()

  const handleChange = (e) => {
    let newValues = { ...filter, [e.target.name]: e.target.value }
    setFilter(newValues)
    if (typeof onChange === "function") onChange(newValues)
  }

  const handleFilterChange = (e) => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setProviderFilter({ ...providerFilter, [e.target.name]: e.target.value })
    }, 500)
  }

  return (
    <div className="content-header">
      <input
        name="searchStr"
        placeholder="search"
        onChange={handleFilterChange}
      />
      <div
        className="icon-only-btn reload-icon"
        onClick={() => reloadProviders()}
      >
        {getSvg("reload")}
      </div>
      {providerFilter != null && (
        <Button
          onClick={() => setProviderFilter(null)}
          value={getT("providers.filter.showAll")}
        />
      )}
      <ProviderSelector />
      <input
        type="date"
        name="dateFrom"
        value={filter.dateFrom || moment().format("YYYY-MM-DD")}
        onChange={handleChange}
      />
      <input
        type="time"
        name="timeFrom"
        value={filter.timeFrom || "00:00"}
        onChange={handleChange}
      />
      <input
        type="date"
        name="dateTo"
        value={filter.dateTo || moment().format("YYYY-MM-DD")}
        onChange={handleChange}
      />
      <input
        type="time"
        name="timeTo"
        value={filter.timeTo || "23:59"}
        onChange={handleChange}
      />
    </div>
  )
}
export default Header
