import { useState, createContext, useEffect } from "react"
import useUser from "hooks/useUser"
import useProvider from "hooks/useProvider"
import { ntakWorkDays } from "apis/ntak/day"
import { toast } from "react-toastify"
import useMultilang from "hooks/useMultilang"
import moment from "moment"

const WorkDaysContext = createContext()

const WorkDaysProvider = (props) => {
  const { userToken } = useUser()
  const { selectedProvider } = useProvider()
  const { getT } = useMultilang()
  // eslint-disable-next-line
  const [workDays, setWorkDays] = useState([])
  const [filter, setFilter] = useState({
    dayFrom: moment().subtract(30, "day").format("YYYY-MM-DD"),
    dayTo: moment().format("YYYY-MM-DD"),
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedProvider?.id != null) {
      reloadWorkDays()
    }
    // eslint-disable-next-line
  }, [userToken, filter, selectedProvider])

  const reloadWorkDays = (withoutLoading) => {
    if (!withoutLoading) setLoading(true)
    if (withoutLoading) {
      toast.info(getT("workDays.reloaded"))
    }
    ntakWorkDays
      .getWorkDays(userToken, selectedProvider?.id, filter)
      .then((res) => {
        if (res.success) {
          setWorkDays(res.data.days)
        } else {
          console.log(res)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
  }

  const saveWorkDay = (day) => {
    return ntakWorkDays.setWorkDay(userToken, selectedProvider?.id, day)
  }

  return (
    <WorkDaysContext.Provider
      value={{
        workDays,
        loading,
        reloadWorkDays,
        setFilter,
        saveWorkDay,
        filter,
      }}
    >
      {props.children}
    </WorkDaysContext.Provider>
  )
}

export { WorkDaysContext, WorkDaysProvider }
