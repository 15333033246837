import { useState, createContext, useEffect } from "react"
import useUser from "hooks/useUser"
import useProvider from "hooks/useProvider"
import { toast } from "react-toastify"
import useMultilang from "hooks/useMultilang"
import { ntakUsers } from "apis/user/user"

const NtakUsersContext = createContext()

const NtakUsersProvider = (props) => {
  const { userToken } = useUser()
  const { selectedProvider } = useProvider()
  const { getT } = useMultilang()
  // eslint-disable-next-line
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (selectedProvider?.id != null) {
      reloadUsers()
    }
    // eslint-disable-next-line
  }, [userToken, selectedProvider])

  const reloadUsers = (withoutLoading) => {
    if (!withoutLoading) setLoading(true)
    if (withoutLoading) {
      toast.info(getT("ntakUsers.reloaded"))
    }
    ntakUsers
      .getUsers(userToken, selectedProvider?.id)
      .then((res) => {
        if (res.success) {
          setUsers(res.data)
        } else {
          console.log(res)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      })
  }

  const saveUserData = (userData) => {
    return ntakUsers.setUser(userToken, selectedProvider?.id, userData)
  }

  const getUserData = (userID) => {
    return ntakUsers.getUser(userToken, selectedProvider?.id, userID)
  }

  const checkUserEmail = (email) => {
    return ntakUsers.checkUserEmail(userToken, selectedProvider?.id, email)
  }

  const setUserToProvider = (userID, selectedProviderID, roles, enabled) => {
    return ntakUsers.setUserToProvider(
      userToken,
      selectedProviderID,
      userID,
      roles,
      enabled
    )
  }

  return (
    <NtakUsersContext.Provider
      value={{
        saveUserData,
        loading,
        reloadUsers,
        users,
        getUserData,
        setUserToProvider,
        checkUserEmail,
      }}
    >
      {props.children}
    </NtakUsersContext.Provider>
  )
}

export { NtakUsersContext, NtakUsersProvider }
